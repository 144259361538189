"use client"

import { useMounted } from "@/shared/hooks/use-mounted"
import { clearState } from "@/store/global-store"
import { useRouter } from "next/navigation"
import { useEffect } from "react"

export default function Page() {
  const isMounted = useMounted()
  const router = useRouter()

  useEffect(() => {
    if (!isMounted) {
      return
    }
    clearState()
    router.replace("/quote/petName")
  }, [router, isMounted])

  return null
}
